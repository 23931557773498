import React, {useState, useEffect} from "react";
import { urlFor, client } from "../../client";
import { useNavigate } from 'react-router-dom';
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";



const Product = () => {
  const navigate = useNavigate();

      // const [allproductData, setAllProductData] = useState([]);

      // useEffect(() => {
      //     async function fetchData() {
      //       try {
      //         const response = await client.fetch(
      //           `*[_type == "allproduct"] | order(createdAt desc)`
      //         );
      //         console.log(response);
      //         setAllProductData(response);
      //       } catch (error) {
      //         console.log(error);
      //       }
      //     }
      //     fetchData();
      //   }, []);

      const [productData, setProductData] = useState([]);

      useEffect(() => {
          async function fetchData() {
            try {
              const response = await client.fetch(
                `*[_type == "product"] | order(createdAt desc)`
              );
              // console.log(response);
              setProductData(response);
            } catch (error) {
              console.log(error);
            }
          }
          fetchData();
        }, []);
        

  return (
    <div>


      {/* Section2 */}
      {productData.length === 0 ? (
        <section>
          <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
            <header className="grid">
              <div className="h-8 w-64 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-8 w-96 mt-3 bg-gray-200 rounded animate-pulse"></div>
            </header>
            <ul className="grid gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-4">
              <li>
                <div className="relative block overflow-hidden group border-gray-300 shadow rounded-md mx-auto bg-white">
                  <div className="h-48 p-3 overflow-hidden bg-gray-200 animate-pulse"></div>
                  <div className="p-3">
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                      <div className="..."></div>
                      <div className="col-span-2 ..."></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="relative block overflow-hidden group border-gray-300 shadow rounded-md mx-auto bg-white">
                  <div className="h-48 p-3 overflow-hidden bg-gray-200 animate-pulse"></div>
                  <div className="p-3">
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                      <div className="..."></div>
                      <div className="col-span-2 ..."></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="relative block overflow-hidden group border-gray-300 shadow rounded-md mx-auto bg-white">
                  <div className="h-48 p-3 overflow-hidden bg-gray-200 animate-pulse"></div>
                  <div className="p-3">
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                      <div className="..."></div>
                      <div className="col-span-2 ..."></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="relative block overflow-hidden group border-gray-300 shadow rounded-md mx-auto bg-white">
                  <div className="h-48 p-3 overflow-hidden bg-gray-200 animate-pulse"></div>
                  <div className="p-3">
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                      <div className="..."></div>
                      <div className="col-span-2 ..."></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      ) : (
       
          <section >
            <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6  lg:px-8">
              <div className="flex items-center justify-between pb-6">
                <div>
                  <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
                    Semua Produk
                  </h2>
                  <p className="max-w-xl mt-4 text-gray-500 text-justify">
                  Kami memiliki daftar produk berkualitas dengan fitur dan keunggulan unggul. 
                  Temukan solusi yang tepat untuk masalah Anda. Jelajahi produk kami.
                  </p>
                </div>
              </div>

              <ul className="grid gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-4">
                {productData.map((product,index) => (
                  <LazyLoadComponent key={index}>
                  <li key={index}>
                    <div className="relative block overflow-hidden group border border-gray-300 shadow rounded-md duration-300 ease-in-out transition-transform transform hover:-translate-y-2">
                      {product.imgproduct.slice(0 , 1).map((image, index) => (
                        <img
                          key={index}
                         
                          src={urlFor(image).url()}
                          alt={product.namaproduk}
                          placeholder={urlFor(image).url()}
                          effect="blur"
                          className="object-contain w-full h-44 transition duration-500 group-hover:scale-105 sm:h-72"/>
                      ))}
                      <div className="relative p-6 bg-white border border-gray-100">
                        {/* <h3 className="mt- text-md font-medium text-gray-900">
                          Jenis {product.jenis}
                        </h3> */}
                        <h3 className="mt-2 text-lg font-semibold text-gray-900 line-clamp-1">
                          {product.namaproduk}
                        </h3>

                        <p className="mt-1.5 text-sm text-gray-700 line-clamp-3">
                          {product.descsingkat}
                        </p>

                        <div
                          id="whoobe-1okdg"
                          className="w-full justify-start flex flex-col">
                          <button 
                            onClick={() =>navigate(`/product-detail/${product._id}`)}
                            value="button"
                            className="mt-4 px-4 text-center rounded-sm py-2 text-white hover:bg-red-700 bg-red-500"
                            id="whoobe-jkkr2">
                            Lihat Detail
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  </LazyLoadComponent>
                ))}
              </ul>
            </div>
          </section>
        
      )}
    </div>
  );
};

export default Product;

import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import { urlFor, client } from "../../client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"

import './OurProduct2.css'
import { images } from '../../constants';
import { useAnimationControls, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';



const OurProduct2 = () => {

    const [aboutData, setAboutData] = useState([]);

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await client.fetch(
              `*[_type == "about"] | order(createdAt desc)`
            );
            // console.log(response);
            setAboutData(response);
          } catch (error) {
            console.log(error);
          }
        }
        fetchData();
      }, []);
      

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
    <div
        className={className}
        style={{ ...style, display: 'block', }}
        onClick={onClick}
    >
    </div>
    );
}; 
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
    <div
        className={className}
        style={{ ...style, display: 'block', }}
        onClick={onClick}
    >
    </div>
    );
};
//Slider settings
const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll:1,
    adaptiveHeight: true,
    vertical: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dotClassName: "my-custom-dot",
}

    const ourProduct2 = useAnimationControls();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            ourProduct2.start({
                opacity:1,
                transition: {
                    ease:'easeIn', duration:1
                }
            });
            // console.log('in view')
        }
        if (!inView) {
            ourProduct2.start({
                opacity:0,
                transition: {
                    duration: 1
                }
            });
            // console.log('not in view');
        }
    }, [ourProduct2, inView]);

return (
<section ref={ref} className="testimonials bg-white">
    <div className="container px-5 py-5 mx-auto">
        <div className="row">

        {aboutData == null ? (<></>) : 
                  (<>

            <motion.div 
            animate={ourProduct2}
            className="">
                <Slider {...settings}>
                    {aboutData.map((card, index) => (
                        <div key={index} className="
                            rounded-md
                            mx-3 
                            w-full 
                            h-full 
                            relative
                            xl:mx-16
                            xl:w-[1200px]
                            xl:h-[670px]
                            2xl:w-screen
                            2xl:h-screen
                            2xl:px-28
                            2xl:py-16
                            ">
                            <img src={urlFor(card.imageurl).url()} alt="carousel1" className="img-carousel 2xl:px-16 2xl:py-16  xl:px-14 py-10"/>
                            <motion.div 
                            whileHover={{ 
                                scale: 1.05,
                                transition: {
                                    duration: 0.4
                                }
                            }}
                            className="
                                w-[300px] 
                                h-[300px] 
                                p-10 
                                bg-black 
                                opacity-80 
                                relative 
                                z-10 
                                bottom-[250px]
                                right-[-8px]
                                xl:p-10
                                xl:w-[600px]
                                xl:h-[400px]
                                xl:bottom-[600px]
                                xl:right-[-350px]
                                lg:w-[600px]
                                lg:h-[400px]
                                lg:bottom-[600px]
                                lg:right-[-350px]
                                ">
                                <h3 className="
                                font-bold 
                                text-white 
                                text-xl 
                                mb-2 
                                pl-[-10px] 
                                xl:pl-[-30px]
                                xl:text-2xl
                                lg:pl-[-30px]
                                lg:text-2xl
                                ">{card.title}</h3>
                                <p className="
                                text-white 
                                text-base">
                                {card.desc}
                                </p>
                                {/* <ul className='list-disc text-white pl-4'>
                                    {card.features.map((feature, index) => 
                                        <li key={index} className="pl-4"> { feature } </li>
                                    )}
                                </ul> */}
                            </motion.div>
                        </div>
                    ))}
                    
                </Slider>
            </motion.div>
            </>) }
        </div>
    </div>
</section>
)
}

export default OurProduct2
import React, { useEffect } from 'react'
import { useAnimationControls ,motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import './WhyUs2'

const WhyUs2 = () => {
    const whyUs = useAnimationControls();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            whyUs.start({
                opacity:[0, 1],
                transition: {
                    ease:'easeIn', duration: 1.5
                }
            });
            // console.log('in view')
        }
        if (!inView) {
            whyUs.start({
                opacity:0,
                transition: {
                    duration: 1
                }
            });
            // console.log('not in view');
        }
    }, [whyUs, inView]);
    return (
        <div>
            <section className="text-gray-600 body-font bg-white">
                <div ref={ref} className="container mx-auto flex py-8 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        
                    </div>
                    <motion.div
                    animate={
                        whyUs
                    }
                    initial={{
                        opacity: 0
                    }}
                    className="px-8 lg:flex-grow md:w-1/2 lg:pl-24 2xl:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <p className="mb-10 leading-9 tracking-tighter lg:mr-3 2xl:mr-6 2xl:pl-[12rem] text-justify">
                            Kami selalu berusaha memberikan peralatan berkualitas tinggi
                            dengan teknologi terbaik dan ketahanan produk yang luar biasa. 
                            Hal ini telah membuat kami dipercaya untuk melayani lebih dari
                            740 layanan. Kami terus berupaya meningkatkan layanan kami demi
                            kepuasan pelanggan.
                        </p>
                    </motion.div>
                </div>
            </section>
        </div>
    )
}

export default WhyUs2
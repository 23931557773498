import React, {useEffect} from 'react'
import { images, videos } from '../../constants';
import { FaPlay } from 'react-icons/fa';
import './Service'
import { NavLink } from "react-router-dom";
import { useAnimationControls ,motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Service = () => {
    const service1 = useAnimationControls();
    const service2 = useAnimationControls();
    const service3 = useAnimationControls();
    const service4 = useAnimationControls();
    const service5 = useAnimationControls();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            service1.start({
                opacity:[0, 1],
                y:[-300, 0],
                transition: {
                    ease:'easeIn',
                    duration:1.1
                }
            });
            service2.start({
                opacity:[0, 1],
                x:[300, 0],
                transition: {
                    ease:'easeIn',
                    duration:1.3
                }
            });
            service3.start({
                opacity:[0, 1],
                x:[-300, 0],
                transition: {
                    ease:'easeIn',
                    duration:1.5
                }
            });
            service4.start({
                opacity:[0, 1],
                y:[300, 0],
                transition: {
                    ease:'easeIn',
                    duration:1.7
                }
            });
            service5.start({
                opacity:[0, 1],
                x:[300, 0],
                transition: {
                    ease:'easeIn',
                    duration:1.9
                }
            });
            // console.log('in view')
        }
        if (!inView) {
            service1.start({
                opacity:0,
                transition: {
                    duration: 1
                }
            });
            service2.start({
                opacity:0,
                transition: {
                    duration: 1
                }
            });
            service3.start({
                opacity:0,
                transition: {
                    duration: 1
                }
            });
            service4.start({
                opacity:0,
                transition: {
                    duration: 1
                }
            });
            service5.start({
                opacity:0,
                transition: {
                    duration: 1
                }
            });
            // console.log('not in view');
        }
    }, [
        service1, 
        service2,
        service3,
        service4,
        service5,
        inView
    ]);
    return (
        <div>
            <section ref={ref} className="text-gray-600 body-font bg-[#EBF0F6]">
                {/* Featured */}

                <div className="py-16 mx-auto max-w-7xl sm:px-6 lg:px-20">
                    <div className="grid grid-cols-1 gap-5 mt-12 sm:grid-cols-3 lg:mt-20 lg:gap-x-12  lg:gap-y-4 lg:mx-20">
                        <div>
                            <div className="rounded-md w-[320px] h-[352px] ml-[18px]">
                                <h2 className="text-4xl text-left">Layanan Kami</h2>
                                <p className="mt-4 text-[18px] text-left text-gray-600">
                                    Kami menyediakan   peralatan untuk berbagai kebutuhan,
                                    mulai dari proses untuk farmasi, makanan, kosmetik, kimia,
                                    dan herbal. Kami juga menyediakan jasa maintenence
                                    untuk setiap produk yang telah dijual.
                                </p>
                            </div>
                        </div>

                        <div>
                            <motion.div 
                            animate={service1}
                            whileHover={{ 
                                scale:1.075,
                                boxShadow:"5px 5px 0 rgba(0, 0, 0, 0.1)",
                                position:'fixed',
                                }} className="rounded-md w-[320px] h-[352px] ml-[18px] p-6 bg-white">
                                <div className="inline-block">
                                    <img src={images.medicine} className='w-8 h-8 inline-block' alt="farmasi" />
                                    <h2 className="inline-block text-4xl align-middle pl-3">Farmasi</h2>
                                </div>
                                <p className="mt-4 text-[18px] text-left text-gray-600">
                                    Kami menyediakan peralatan untuk keperluan farmasi, seperti
                                    pemrosesan obat atau bahan farmasi lainnya.
                                </p>
                                <div className='text-blue-400 pt-[80px]'>
                                    <NavLink to='/product'>Lihat produk kami</NavLink>
                                </div>
                            </motion.div>
                        </div>

                        <div>
                            <motion.div
                            animate={service2}
                            whileHover={{ 
                                scale:1.075,
                                boxShadow:"5px 5px 0 rgba(0, 0, 0, 0.1)",
                                position:'fixed',
                                }}
                            className="rounded-md w-[320px] h-[352px] ml-[18px] p-6 bg-white">
                                <div className="inline-block">
                                    <img src={images.fastfood} className='w-8 h-8 inline-block' alt="fastFood" />
                                    <h2 className="inline-block text-4xl align-middle pl-3">Makanan</h2>
                                </div>
                                <p className="mt-4 text-[18px] text-left text-gray-600">
                                    Kami menyediakan peralatan untuk keperluan pengolahan makanan,
                                    seperti pemrosesan bahan makanan dan lainnya.
                                </p>
                                <div className='text-blue-400 pt-[55px]'>
                                    <NavLink to='/product'>Lihat produk kami</NavLink>
                                </div>
                            </motion.div>
                        </div>

                        <div>
                            <motion.div
                            animate={service3}
                            whileHover={{ 
                                scale:1.075,
                                boxShadow:"5px 5px 0 rgba(0, 0, 0, 0.1)",
                                position:'fixed',
                                }}
                            className="rounded-md w-[320px] h-[352px] ml-[18px] p-6 bg-white">
                                <div className="inline-block">
                                    <img src={images.lipstickwithcover} className='w-8 h-8 inline-block' alt="lipstick" />
                                    <h2 className="inline-block text-4xl align-middle pl-3">Kosmetik</h2>
                                </div>
                                <p className="mt-4 text-[18px] text-left text-gray-600">
                                Kami menyediakan peralatan untuk keperluan industri kosmetik, seperti pemrosesan 
                                produk kecantikan seperti parfum, kosmetik, dan lainnya.
                                </p>
                                <div className='text-blue-400 pt-[30px]'>
                                    <NavLink to='/product'>Lihat produk kami</NavLink>
                                </div>
                            </motion.div>
                        </div>

                        <div>
                            <motion.div
                            animate={service4}
                            whileHover={{ 
                                scale:1.075,
                                boxShadow:"5px 5px 0 rgba(0, 0, 0, 0.1)",
                                position:'fixed',
                                }}
                            className="rounded-md w-[320px] h-[352px] ml-[18px] p-6 bg-white">
                                <div className="inline-block">
                                    <img src={images.chemicals} className='w-8 h-8 inline-block' alt="chemicals" />
                                    <h2 className="inline-block text-4xl align-middle pl-3">Kimia</h2>
                                </div>
                                <p className="mt-4 text-[18px] text-left text-gray-600">
                                Kami menyediakan peralatan untuk keperluan industri kimia, 
                                seperti pemrosesan bahan kimia atau produk kimia lainnya.
                                </p>
                                <div className='text-blue-400 pt-[55px]'>
                                    <NavLink to='/product'>Lihat produk kami</NavLink>
                                </div>
                            </motion.div>
                        </div>

                        <div>
                            <motion.div
                            animate={service5}
                            whileHover={{ 
                                scale:1.075,
                                boxShadow:"5px 5px 0 rgba(0, 0, 0, 0.1)",
                                position:'fixed',
                                }}
                            className="rounded-md w-[320px] h-[352px] ml-[18px] p-6 bg-white">
                                <div className="inline-block">
                                    <img src={images.gear} className='w-8 h-8 inline-block' alt="gear" />
                                    <h2 className="inline-block text-4xl align-middle pl-3">Service</h2>
                                </div>
                                <p className="mt-4 text-[18px] text-left text-gray-600">
                                Kami menyediakan jasa maintenance untuk produk yang telah kami jual. 
                                Layanan ini memastikan produk tetap dapat digunakan dengan optimal.
                                </p>
                                <div className='text-blue-400 pt-[26px]'>
                                    <NavLink to='/product'>Lihat produk kami</NavLink>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Service
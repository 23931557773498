import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { urlFor, client } from "../../client";
import OwlCarousel from "react-owl-carousel";
import ReactPlayer from 'react-player'
import {PortableText} from '@portabletext/react'

const DetailProduct = () => {
    const [productDetail, setProductDetail] = useState(null);
    const { productId } = useParams();
    const [loading, setLoading] = useState(false);
  
      useEffect(() => {
        setLoading(true);
        async function fetchData() {
          try {
            const response = await client.fetch(
              `*[_type == "product" && _id == "${productId}"]{
                imgproduct,
                namaproduk,
                listfitur,
                linkyoutube,
                descsingkat,
                desclengkap
              }
            `
            );
            // console.log(response);
            setProductDetail(response);
            setLoading(false);
          } catch (error) {
            console.log(error);
          }
        }
        fetchData();
      }, []);

      //Owl Carousel Settings
const options = {
   loop:true,
    center: true,
    items: 1,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
   
  };
  
  const myPortableTextComponents = {
   
    list: {
      // Ex. 1: customizing common list types
      bullet: ({children}) => <ul className="mt-xl ">{children}</ul>,
      number: ({children}) => <ol className="mt-lg ">{children}</ol>,
  
      // Ex. 2: rendering custom lists
      checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
    },
    listItem: {
      // Ex. 1: customizing common list types
      bullet: ({children}) => <li className="list-disc mt-xl ml-4">{children}</li>,
      number: ({children}) => <ol className="mt-lg">{children}</ol>,
  
      // Ex. 2: rendering custom lists
      checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
    },
  }

  if(loading) return(<>
   {/* skeleton loading */}
   <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
                <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8"></div>

                {/* rightside */}
                <div className="mt-4 lg:row-span-3 lg:mt-0">
                  {/* carousel */}
                  <div className="aspect-w-4 aspect-h-5 sm:overflow-hidden sm:rounded-lg lg:aspect-w-3 lg:aspect-h-4 border">
                    <div className="flex items-center justify-center h-96 p-3 overflow-hidden bg-gray-300 animate-pulse">
                      <svg
                        className="w-12 h-12 text-gray-200 "
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 640 512">
                        <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                      </svg>
                    </div>
                  </div>
                  {/* endcarousel */}

                  {/* video */}
                  <div className="mt-2">
                  <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                    <div className="mt-4 space-y-6">
                      <div
                        role="status"
                        className="flex items-center justify-center h-56 max-w-sm bg-gray-300 rounded-lg animate-pulse">
                        <svg
                          className="w-12 h-12 text-gray-200 "
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 384 512">
                          <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z" />
                        </svg>
                        
                      </div>
                    </div>
                  </div>
                  {/* endvideo */}
                </div>
                {/* endrightside */}

                <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
                  {/* Description and details */}
                  <div>
                    <p className="text-base text-gray-900">
                      <div className="grid grid-cols-2 gap-4 mt-2">
                        <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                        <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                        <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                      </div>
                    </p>
                  </div>

                  {/* Product fitur */}
                  <div className="mt-10">
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                    </div>
                  </div>
                  <div className="mt-10 mb-24">
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
                    </div>
                  </div>
                  {/* endproductfitur */}
                </div>
              </div>
              {/* endskeleton loading  */}
  </>) 

  return (
    <div>
      
      {productDetail && productDetail.length === 0 ? (
      <section>

      </section>) : (
      <section>

      </section>)}

      

      {productDetail &&
        productDetail.map((product, index) => (
          <div key={index} className="bg-white">
            <div className="pt-6">
              <nav aria-label="Breadcrumb">
                <ol
                  role="list"
                  className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                  <li key="">
                    <div className="flex items-center">
                      <p className="mr-2 text-sm font-medium text-red-500">
                        Produk
                      </p>
                      <svg
                        width={16}
                        height={20}
                        viewBox="0 0 16 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-5 w-4 text-gray-300">
                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                      </svg>
                    </div>
                  </li>

                  <li className="text-sm">
                    <p
                      aria-current="page"
                      className="font-medium text-gray-500 hover:text-gray-600">
                      {product.namaproduk}
                    </p>
                  </li>
                </ol>
              </nav>


              {/* Product info */}
              <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
                <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                  <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                    {product.namaproduk}
                  </h1>
                </div>

                {/* rightside */}
                <div className="mt-4 lg:row-span-3 lg:mt-0">
                  {/* carousel */}
                  <div className="aspect-w-4 aspect-h-5 sm:overflow-hidden sm:rounded-lg lg:aspect-w-3 lg:aspect-h-4 border">
                    {product.imgproduct.length && (
                      <OwlCarousel id="customer-testimonoals" {...options}>
                        {product.imgproduct.slice(0, 4).map((image, index) => (
                          <img
                            key={index}
                            src={urlFor(image).url()}
                            alt={product.namaproduk}
                            className="h-full w-full object-cover object-center"
                          />
                        ))}
                      </OwlCarousel>
                    )}
                  </div>
                  {/* endcarousel */}

                  {/* video */}
                  {product.linkyoutube == null ? (<></>) : 
                  (<>
        
                  <div className="mt-2">
                    <h2 className="text-sm font-medium text-gray-900">
                      Overview Video
                    </h2>
                    <div className="mt-4 space-y-6">
                      <ReactPlayer
                        className="aspect-video object-cover object-center "
                        url={product.linkyoutube}
                        playIcon={
                          <button
                            type="button"
                            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-4 text-center inline-flex items-center mr-2 "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M2 24v-24l20 12-20 12z" />
                            </svg>
                          </button>
                        }
                        // playing
                        controls={true}
                        // light={images.thumbnail}
                        width="100%"
                        height="100%"
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  </>) }
                  {/* endvideo */}
                </div>
                {/* endrightside */}

                <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
                  {/* Description and details */}
                  <div>
                    <h3 className="sr-only">Description</h3>
                    <div className="space-y-6">
                      <p className="text-base text-gray-900 text-justify">
                        {product.descsingkat}
                      </p>
                    </div>
                  </div>

                  {/* Product fitur */}
                  <div className="mt-8">
                    {console.log(product.listfitur.fiturpenting)}
                    {product.listfitur.map((listfiturdata, index) => (
                      <>
                        <h3 key={index} className="text-base font-semibold text-gray-900">
                          {listfiturdata.nama}
                        </h3>
                        <div className="mt-4">
                          <ul
                            role="list"
                            className="list-disc space-y-2 pl-4 text-sm"
                          >
                            {listfiturdata.fiturpenting.map(
                              (fiturpenting, index) => (
                                <li key={index} className="text-gray-400">
                                  <span className="text-gray-600">
                                    {fiturpenting}
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </>
                    ))}
                  </div>
                  {/* endproductfitur */}

                  <div className="mt-8">
                    <h2 className="text-base font-semibold text-gray-900">
                      Detail
                    </h2>
                    <div className="mt-4 space-y-6">
                    
                        {product.desclengkap == null ? (<></>) :
                         (<div className='text-sm text-gray-600 text-justify'>
                          {product.desclengkap.map((desclengkap, index) => (<>
                          <PortableText value={desclengkap} components={myPortableTextComponents} />
                          </>))}
                         
                         </div>)}
                       
                        
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default DetailProduct


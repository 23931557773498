import React from "react";
import { images } from "../../constants";

const Contact = () => {
  return (
    <>
      {/* Hero Contact */}
      <div className="relative px-6 lg:px-8">
        <img
          className="absolute inset-0 w-full h-full object-cover object-top"
          src={images.bghero3}
          width="400"
          height="500"
          alt="hero background image"
        />
        <div className="relative text-white mx-auto max-w-4xl pt-20 pb-32 sm:pt-48 sm:pb-40">
          <div>
            <div>
              <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">
                Apa yang anda butuhkan?
              </h1>
              <div className="pt-5 invisible lg:visible  md:invisible">
                <hr className="mx-32 h-1 bg-white rounded border-0" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Map */}
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap ">
          <div className="lg:w-1/2 w-full mb-10 lg:mb-0  overflow-hidden flex justify-end items-center sm:border-r-4 md:pr-10 border-r-4-hidden border-black">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.656238431341!2d107.68460401490663!3d-6.93162746977683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68c2b6b50d3e5d%3A0x4ac607084b71e633!2sBANGUN%20RAHMAT%20TEKNIK!5e0!3m2!1sid!2sid!4v1673877116562!5m2!1sid!2sid"
              alt="feature"
              className="object-cover object-center h-full w-[500px]"
            />
          </div>
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
            <div className="flex flex-col mb-2 lg:items-start items-center">
              <p className="mb-3 font-bold">Hubungi Kami</p>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-4xl title-font font-semibold mb-3">
                  Hubungi kami sekarang juga!
                </h2>
                <p className="leading-relaxed text-base mb-6">
                  Kami sebagai produsen mesin pabrik, mengerti perilaku objek
                  dalam skala besar dan kecil...
                </p>
                <p className="leading-relaxed text-base">
                  Phone : +62 812-2288-8726
                </p>
                <p className="leading-relaxed text-base ">
                  Email :{" "}
                  <a
                    href="mailto:brteknik@yahoo.com"
                    className="hover:text-blue-500 hover:font-medium"
                  >
                    brteknik66a@gmail.com
                  </a>
                </p>
                <p className="leading-relaxed text-base ">
                  Email :{" "}
                  <a
                    href="mailto:zaka.zaenudin@gmail.com"
                    className="hover:text-blue-500 hover:font-medium"
                  >
                    zaka.zaenudin@gmail.com
                  </a>
                </p>
              </div>

              <div className="flex justify-between mt-60 ">
                {/* <FaFacebook icon="fa-brands fa-facebook" className="w-10 h-10 mr-4" />
                <FaInstagram icon="fa-brands fa-instagram" className="w-10 h-10 mr-4"/>
                <FaTwitter icon="fa-brands fa-twitter" className="w-10 h-10 mr-4"/> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

import React, {useEffect} from 'react'
import { images ,videos} from '../../constants';
import { FaPlay } from 'react-icons/fa';
import './WhyUs'
import ReactPlayer from 'react-player'
import { useAnimationControls ,motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WhyUs = () => {
    const whyUs2 = useAnimationControls();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
          whyUs2.start({
                opacity:[0, 1],
                y:[300, 0],
                transition: {
                    ease:'easeInOut', duration: 1.7
                }
            });
            console.log('in view')
        }
        if (!inView) {
          whyUs2.start({
                opacity:0,
                y:300,
                transition: {
                    duration: 1
                }
            });
            console.log('not in view');
        }
    }, [whyUs2, inView]);
  return (
    <div>
      <section ref={ref} className="text-gray-600 body-font bg-white">
        <motion.div whileInView={{ 
          opacity:[0, 1],
          y:[-100, 0],
          transition: {
            ease:'easeInOut', duration: 1.5
          }
        }} initial={{ opacity: 0 }} className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className=" lg:max-w-xl lg:w-full md:w-4/3 w-5/6 mb-10 md:mb-0">
            <ReactPlayer
              className="aspect-video object-cover object-center "
              url="https://www.youtube.com/watch?v=4NkWe-CbPhI"
              // url={videos.videobrt}
              // playIcon={
              //   <button
              //     type="button"
              //     className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-4 text-center inline-flex items-center mr-2 ">
              //     <svg
              //       xmlns="http://www.w3.org/2000/svg"
              //       fill="currentColor"
              //       width="24"
              //       height="24"
              //       viewBox="0 0 24 24"
              //     >
              //       <path d="M2 24v-24l20 12-20 12z" />
              //     </svg>
              //   </button>
              // }
              
              autoPlay={true}
              playing={true}
              controls={true}
              // light={images.thumbnail}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
            {}
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-14 md:pl-14 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-semibold text-gray-900">
              Mengapa Kami?
            </h1>
            <p className="mb-8 leading-loose text-justify pr-8">
              Selama 20 tahun terakhir, kami telah melayani lebih dari 540
              perusahaan dengan selalu mengutamakan kualitas dan teknologi
              terbaru dalam setiap produk yang kami hasilkan. Ini telah
              menjadikan kami pilihan utama bagi banyak pelanggan yang ingin
              mendapatkan layanan terbaik.
            </p>
          </div>
        </motion.div>

        {/* Featured */}

        <motion.div whileInView={{
          x: [-300, 0],
          opacity: [0, 1],
          transition: {
            ease:'easeInOut', duration: 1.15, delay:0.2
          },
        }} className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 mb-24">
          <div className="grid grid-cols-1 text-center sm:grid-cols-2 gap-y-8 lg:grid-cols-4 sm:gap-12">
            <motion.div whileHover={{ 
              scale:1.075,
              transition:{
                duration: 0.25
              }
            }}>
              <div className="flex items-center justify-center w-20 h-20 mx-auto bg-red-100 rounded-full">
                <img src={images.medicine} className="w-12" alt="farmasi" />
              </div>
              <h3 className="mt-6 text-2xl font-semibold mx-12 text-black">
                Farmasi
              </h3>
              <p className="mt-4 text-sm text-gray-600">
                Kami membuat alat untuk kebutuhan farmasi mulai dari penghalusan
                bahan hingga pengkapsulan.
              </p>
            </motion.div>

            <motion.div whileHover={{ 
              scale:1.075,
              transition:{
                duration: 0.25
              }
            }}>
              <div className="flex items-center justify-center w-20 h-20 mx-auto bg-red-100 rounded-full">
                <img src={images.fastfood} className="w-12" alt="food" />
              </div>
              <h3 className="mt-6 text-2xl font-semibold mx-12 text-black">
                Makanan
              </h3>
              <p className="mt-4 text-sm text-gray-600">
                Berbagai macam bahan untuk produk makanan dapat diolah dengan
                alat-alat yang kami sediakan.
              </p>
            </motion.div>

            <motion.div whileHover={{ 
              scale:1.075,
              transition:{
                duration: 0.25
              }
            }}>
              <div className="flex items-center justify-center w-20 h-20 mx-auto bg-red-100 rounded-full">
                <img src={images.chemicals} className="w-9" alt="kimia" />
              </div>
              <h3 className="mt-6 text-2xl font-semibold mx-12 text-black">Kimia</h3>
              <p className="mt-4 text-sm text-gray-600">
                Kami menyediakan alat yang dapat digunakan mulai dari proses
                pecampuran bahan-bahan yang dibutuhkan hingga proses
                pengeringan..
              </p>
            </motion.div>

            <motion.div whileHover={{ 
              scale:1.075,
              transition:{
                duration: 0.25
              }
            }}>
              <div className="flex items-center justify-center w-20 h-20 mx-auto bg-red-100 rounded-full">
                <img
                  src={images.lipstickwithcover}               className="w-9"alt="cosmetic"/>
              </div>
              <h3 className="mt-6 text-2xl font-semibold mx-12 text-black">
                Kosmetik
              </h3>
              <p className="mt-4 text-sm text-gray-600">
                Menggunakan alat-alat terbaik, anda akan mendapatkan hasil yang
                diinginkan sesuai dengan kebutuhan.
              </p>
            </motion.div>
          </div>
        </motion.div>
      </section>
    </div>
  );
}

export default WhyUs
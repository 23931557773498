import React,{useEffect} from 'react'


import {Hero1,WhyUs,OurProduct,Company,Call, Hero2, WhyUs2, OurProduct2, Mission,DetailProduct, Service,} from './container'; 

import {Footer,Scroll} from './components';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { images } from './constants';
import Contact from './container/Contact/Contact';
import Product from './container/Product/Product';


const App = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/about' element={<AboutPage/>}/>
        <Route path='/product' element={<ProductPage/>}/>
        <Route path='/product-detail/:productId' element={<DetailProductPage/>}/>
        <Route path='/contact' element={<ContactPage/>}/>
      </Routes>
    </BrowserRouter>
  );
};


//Navigation Bar
const NavBar = () => {
  return (
    <header className=" body-font bg-white">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <img src={images.logo} alt="logo" />
          <span className="ml-3 font-bold italic text-4xl">
            BANGUN RAHMAT TEKNIK
          </span>
        </a>
        <nav className="md:ml-auto flex flex-wrap items-center font-semibold text-sm md:text:base justify-center">
      
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "link mr-1 md:mr-5 hover:text-gray-900 p-2 border-b-[3px] border-red-500"
                : "mr-1 md:mr-5 hover:text-gray-900 p-2 link link-underline link-underline-black "
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "link mr-1 md:mr-5 hover:text-gray-900 p-2  border-b-[3px] border-red-500"
                : "mr-1 md:mr-5 hover:text-gray-900 p-2 link link-underline link-underline-black "
            }
          >
            About
          </NavLink>
          <NavLink
            to="/product"
            className={({ isActive }) =>
              isActive
                ? "link mr-1 md:mr-5 hover:text-gray-900 p-2 border-b-[3px] border-red-500"
                : "mr-1 md:mr-5 hover:text-gray-900 p-2 link link-underline link-underline-black "
            }
          >
            Product
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive
                ? "link mr-1 md:mr-5 hover:text-gray-900 p-2 border-b-[3px] border-red-500"
                : "mr-1 md:mr-5 hover:text-gray-900 p-2 link link-underline link-underline-black "
            }
          >
            Contact
          </NavLink>
        </nav>
      </div>
    </header>
  );
}


// Home Page
const HomePage = () => {
  return (
    <div>
      <NavBar />
      <div className="content overflow-hidden">
        <Hero1/>
        <WhyUs/>
        <OurProduct/>
        <Company/>
        <Call/>
        <Scroll />
      </div>
      <Footer/>
    </div>
  );
};

// About Page
const AboutPage = () => {
  return (
    <div>
      <NavBar />
      <div className="content overflow-hidden">
        <Hero2/>
        <WhyUs2/>
        <OurProduct2/>
        <Mission/>
        <Service/>
        <Scroll />
      </div>
      <Footer/>
    </div>
  );
};

// Product Page
const ProductPage = () => {
  return (
    <div>
      <NavBar />
      <div className="content overflow-hidden bgcolor-white">
        <Product/>
        {/* <Scroll /> */}
      </div>
        <Footer/>
    </div>
  );
};
// Product Page
const DetailProductPage = () => {
  return (
    <div>
      <NavBar />
      <div className="content overflow-hidden bgcolor-white">
        <DetailProduct/>
        
      </div>
        <Footer/>
    </div>
  );
};

// Contact Page
const ContactPage = () => {
  return (
    <div>
      <NavBar />
      <div className="content">
        <Contact/>
        <Scroll />
      </div>
      <Footer/>
    </div>
    
  );
};
{/* <div>

<Hero1/>
<WhyUs/>
<OurProduct/>
<Company/>
<Call/>


</div> */}
export default App

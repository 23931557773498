import React, {useEffect} from 'react'

import { images, videos } from '../../constants';
import ReactPlayer from 'react-player';
import { useAnimationControls, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import './Mission'
import './mission.css'
const Mission = () => {
    const mission = useAnimationControls();
    const cardMission = useAnimationControls();

    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            mission.start({
                opacity: 1,
                transition: {
                    duration:1.5
                }
            });
            cardMission.start({
                opacity: 1,
                y:[200, 0],
                transition: {
                    ease:'backInOut',
                    duration:2
                },
            });
            // console.log('in view')
        }
        if (!inView) {
            mission.start({
                opacity: 0,
                transition: {
                    duration:1.5
                }
            });
            cardMission.start({
                opacity: 0,
                transition: {
                    duration: 2,
                }
            });
            // console.log('not in view');
        }
    }, [mission, cardMission,inView]);

    return (
        <div>
            <section ref={ref} className="text-gray-600 body-font bg-white 2xl:px-10">
                <hr className="top-border"></hr>
                <motion.div animate={mission} className="container mx-auto flex py-24 md:flex-row flex-col items-center">
                    <div className=" lg:max-w-lg lg:w-full md:w-4/3 md:mb-0 ml-10 xl:max-w-4xl xl:px-4">
                        <h1 className="
                            title-font 
                            sm:text-4xl 
                            text-2xl 
                            mt-[-60px] 
                            mb-8 
                            text-left 
                            font-semibold 
                            text-gray-900
                            xl:mt-[-200px]
                            xl:text-3xl
                            "
                        >
                            Misi Kami
                        </h1>
                        <p className="mb-4 pr-3 xl:text-base xl:leading-loose xl:pr-0 xl:text-justify">
                        Tujuan kami adalah memenuhi kebutuhan semua 
                        pelanggan dengan menyediakan mesin proses berkualitas
                        tinggi yang dilengkapi dengan teknologi terbaik. 
                        Kami juga mengotomatisasi proses produksi untuk memastikan 
                        keandalan, kemudahan penggunaan, dan umur panjang mesin. 
                        Kami menyediakan berbagai jenis mesin proses untuk keperluan 
                        laboratorium, pemrosesan bubuk, cairan dan salep, R&D, farmasi, 
                        kosmetik, makanan, susu, roti, biskuit, bahan kimia pertanian, 
                        bahan kimia, pewarna herbal, dan banyak lagi.
                        </p>

                    </div>
                    <div className="2xl:flex-grow 2xl:pl-12 lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <div className="container px-5 py-5 mx-auto">
                            <div className="row">
                                <div className="">
                                    <div className="rounded-md relative">
                                        
                                        <img src={images.costumer} alt="mission" className='bg-contain h-[300px] w-[500px] '></img>
                                        <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={cardMission}
                                        whileHover={{ 
                                            scale: 1.05,
                                            transition: {
                                                duration: 0.3
                                            }
                                        }}   
                                        className="w-[380px] h-[90px] bg-[#FA4D3F] relative z-10 bottom-[50px] center xl:right-[50px] lg:right-[50px]">
                                            <p className="text-white font-medium text-4xl text-center pt-6">
                                            "Customer First"
                                            </p>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>


            </section></div>
    )
}

export default Mission
import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: 'sq59ftup',
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: 'skmTHocjcaA3PzM2oSisAh14gEa1zeayluW7LMImahaj0WLJcVHJ6R2V2NWUqHrEu25kJqe8hvLayqg7zJmLMm4stKR1T7xkG8PyAg0vzjNcdnGKay1858YHPOMGOUJsEwK1S9XKdAa8S8EJWsniKpvaEqJpHtfpJGj619DJPXbqvBaZ43Ds',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
import React from "react";
import { images } from "../../constants";
import { NavLink } from "react-router-dom";
const today = new Date();
const year = today.getFullYear();

const Footer = () => {
  return (
    <section className="py-10 bg-[#B00000] sm:pt-16 lg:pt-24 ">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl text-white">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-y-12 gap-x-8 xl:gap-x-12">
          <div className="col-span-2 md:col-span-4 xl:pr-8">
            <div className="inline-flex">
              <img className="w-14 h-auto" src={images.logowhite} alt="" />
              <span className="p-3 text-lg font-semibold">
                CV Bangun Rahmat Teknik
              </span>
            </div>

            <p className="text-base leading-relaxed text-white mt-7">
              Jl. Cisantren Wetan No. 10 Soekarno - Hatta Bandung, Jawabarat.{" "}
              <br /> +62 812-2288-8726
            </p>
          </div>

          <div className="lg:col-span-2">
            <p className="text-base font-semibold text-white">Social Media</p>

            <ul className="mt-6 space-y-5">
              <li>
                <a
                  href="#"
                  title=""
                  className="flex text-sm text-white transition-all duration-200  "
                >
                  {" "}
                  Facebook{" "}
                </a>
              </li>

              <li>
                <a
                  href="mailto:brteknik66a@gmail.com?cc=&bcc=&subject=&body="
                  title=""
                  className="flex text-sm text-white transition-all duration-200  "
                >
                  {" "}
                  Gmail{" "}
                </a>
              </li>

              <li>
                <a
                  href="https://www.youtube.com/@bangunrahmatteknik"
                  title=""
                  className="flex text-sm text-white transition-all duration-200  "
                >
                  {" "}
                  YouTube{" "}
                </a>
              </li>
            </ul>
          </div>

          <div className="lg:col-span-2">
            <p className="text-base font-semibold text-white">Produk</p>

            <ul className="mt-6 space-y-4">
              <li>
                <NavLink
                  to="/product"
                  title=""
                  className="flex text-sm text-white transition-all duration-200  "
                >
                  {" "}
                  List Produk kami{" "}
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="lg:col-span-2">
            <p className="text-base font-semibold text-white">Company</p>

            <ul className="mt-6 space-y-5">
              <li>
                <NavLink
                  to="/about"
                  title=""
                  className="flex text-sm text-white transition-all duration-200  "
                >
                  {" "}
                  Tentang Kami{" "}
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/contact"
                  title=""
                  className="flex text-sm text-white transition-all duration-200  "
                >
                  {" "}
                  Hubungi Kami{" "}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <hr className="mt-16 mb-10 border-gray-200" />
        <div className="grid-cols-3 content-center">
          <p className="text-sm text-white text-center">
            © Copyright {year}, CV Bangun Rahmat Teknik
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;

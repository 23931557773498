import React from 'react'
import { images } from '../../constants'
import { useAnimationControls ,motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Company = () => {
  return (
    <section className="py-10 mt-10 bg-gray-100 sm:py-16 lg:py-24 ">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 sm:gap-x-12 gap-y-">
        
            <div className="lg:col-span-2 relative ">
            <img className="absolute  z-[0] left-[-18%] top-[-45px] w-28 "  src={images.quotemark} alt="" />
                <h2 className="text-3xl desc z-10 font-bold leading-tight text-gray-800 sm:text-4xl lg:text-4xl lg:leading-tight">
                
                    Lebih dari 70<br />
                    Perusahaan telah<br />
                    kami layani.<br />
                </h2>
                <p className="mt-6 text-base text-gray-600">Kami telah melayani lebih dari 70 perusahaan dengan memberikan solusi yang tepat sesuai kebutuhan mereka. Kami terus meningkatkan pengalaman pelanggan dengan mengikuti perkembangan terbaru di industri kami.</p>
            </div>

            <div className="lg:col-span-3 xl:col-span-4">
                <div className="grid items-center max-w-2xl grid-cols-2 mx-auto lg:grid-cols-3 gap-x- gap-y-12">
                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }}>
                        <img className="object-contain w-full h-10 mx-auto" src={images.kraft} alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }}>
                        <img className="object-contain w-full h-20 mx-auto" src={images.sidomuncul} alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }}>
                        <img className="object-contain w-full h-8 mx-auto" src={images.indofood} alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }}>
                        <img className="object-contain w-full mx-auto h-8" src={images.sanbe}alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }} className="hidden lg:block">
                        <img className="object-contain w-full h-8 mx-auto" src={images.kimiafarma} alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }} className="hidden lg:block">
                        <img className="object-contain w-full h-12 mx-auto" src={images.djarum} alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }} className="hidden lg:block">
                        <img className="object-contain w-full h-8 mx-auto" src={images.hisamitsu} alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }} className="hidden lg:block">
                        <img className="object-contain w-full h-24 mx-auto" src={images.mayora} alt="" />
                    </motion.div>

                    <motion.div whileHover={{
                        scale:1.35,
                        transition:{
                            duration:0.3
                        }
                    }} className="hidden lg:block">
                        <img className="object-contain w-full h-8 mx-auto" src={images.nabati} alt="" />
                    </motion.div>

                   
                </div>

            
            </div>
        </div>
    </div>
</section>


  )
}

export default Company
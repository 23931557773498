import React, {useEffect} from 'react'
import { images } from '../../constants'
import { useAnimationControls, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Hero1 = () => {
  const hero1 = useAnimationControls();
  const cardHero1 = useAnimationControls();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
          hero1.start({
                opacity: [0, 1],
                x:[-200, 0],
                transition: {
                  ease:'easeOut', duration:1.7
                }
            });
          cardHero1.start({
              opacity: [0, 1],
              x:[200, 0],
              transition: {
                ease:'easeOut', duration:2
              }
          });
            // console.log('in view')
        }
        if (!inView) {
          hero1.start({
                opacity: 0,
                x:-200,
                transition: {
                  ease:'easeIn', duration:2
                }
            });
          cardHero1.start({
              opacity: 0,
              x:200,
              transition: {
                ease:'easeIn', duration:2
              }
          });
            // console.log('not in view');
        }
    }, [hero1, cardHero1, inView]);
  return (
    <section ref={ref} className="bg-cover 2xl:bg-gray-50" style={{backgroundImage:`url(${images.bghero1})`}}>
    <div className="px-4 mx-auto  max-w-7xl sm:px-6 lg:px-8 2xl:rounded-xl relative">
        <div className="py-10 static sm:py-16 lg:py-24 2xl:pl-24">
            <div className="grid  items-center grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-8 2xl:gap-x-20">
                <motion.div
                animate={cardHero1}
                initial={{ opacity:0 }}
                whileHover={{ 
                  scale:1.05,
                  transition:{
                    duration: 0.2
                  }
                }}
                className="z-10 quote absolute lg:right-[-7%] xl:right-[-10%]  bottom-[-85px]   invisible lg:visible  md:invisible  lg lg:order-2 ">
                  <div className='w-[450px] h-48 bg-black pl-8 py-10' >
                    
                    <hr className='mb-5 w-10 h-1 bg-red-600 rounded border-0'/>
                    <p className='text-white italic font-semibold text-xl leading-9'>" Semua yang bisa dibuat,<br />
                        dapat dibuat lebih baik. "  </p>
                  </div>
                    <img className="w-64 shadow-xl bg-black rounded-xl" src="" alt="" />
                </motion.div>

                <motion.div animate={hero1} initial={{ opacity:0 }} className="lg:order-1">
                    
                    <h1 className="text-3xl lg:leading-snug sm:text-4xl italic font-bold tracking-wide text-white lg:text-5xl"> 
                    PILIHAN TERBAIK UNTUK KEBUTUHAN PERUSAHAAN ANDA
                    </h1>

        

                    <div className="flex flex-col pr-10 items-start mt-6 sm:space-x-4 sm:flex-row sm:items-center lg:mt-12">
                    <p className='text-white font-light text-2xl'>Setiap produk dibuat 
                     dengan <br />menggunakan teknologi terbaik <br /> dan inovatif. </p>

                    </div>
                </motion.div>
            </div>
        </div>
    </div>
</section>

  )
}

export default Hero1
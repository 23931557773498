import arrowleft from '../assets/arrow-left-circle.svg';
import arrowright from '../assets/arrow-right-circle.svg';
import chemicals from '../assets/chemicals.svg';
import fastfood from '../assets/fast-food.svg';
import gear from '../assets/gear.svg';
import lipstickwithcover from '../assets/lipstick-with-cover.svg';
import list from '../assets/list.svg';
import logo from '../assets/Logo.svg';
import logowhite from '../assets/logowhite.svg';
import medicine from '../assets/medicine.svg';
import phone from '../assets/phone.svg';
import quotemark from '../assets/QuoteMark.svg';


import djarum from '../assets/djarum.png';
import hisamitsu from '../assets/hisamitsu.png';
import indofood from '../assets/indofood.png';
import kimiafarma from '../assets/kimiafarma.png';
import kraft from '../assets/kraft.png';
import mayora from '../assets/mayora.png';
import nabati from '../assets/nabati.png';
import sanbe from '../assets/sanbe.png';
import sidomuncul from '../assets/sidomuncul.png';
import bghero1 from '../assets/bghero1.png';
import bghero3 from '../assets/contact2.webp';
import bghero2 from '../assets/bnrabout.webp';
import costumer from '../assets/costumer_first.webp';
import mission1 from '../assets/ourmission.webp';
import carousel1 from '../assets/carousel1.png';
import dummy from '../assets/dummyproduct.png';







export default {
    arrowleft,
    arrowright,
    chemicals,
    fastfood,
    gear,
    lipstickwithcover,
    list,
    logo,
    logowhite,
    medicine,
    phone,
    quotemark,
    djarum,
    hisamitsu,
    indofood,
    kimiafarma,
    kraft,
    mayora,
    nabati,
    sanbe,
    sidomuncul,
    bghero1,
    bghero2,
    bghero3,
    mission1,
    carousel1,
    dummy,
    costumer,
};



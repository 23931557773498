import React, { useEffect } from 'react'
import { images } from '../../constants'
import { useAnimationControls, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const Hero2 = () => {
    const hero2 = useAnimationControls();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
          hero2.start({
                opacity: [0, 100],
                x:[-200, 0],
                transition: {
                  ease:'easeOut', duration:1.5
                }
            });
            // console.log('in view')
        }
        if (!inView) {
          hero2.start({
                opacity: 0,
                x:-200,
                transition: {
                  ease:'easeIn', duration:1.5
                }
            });
            // console.log('not in view');
        }
    }, [hero2, inView]);
  return (
    <section
    className=" 
      2xl:bg-cover 
      xl:bg-cover
      sm:w-full
      sm:bg-contain
      xl:w-full 
      2xl:w-full 
      lg:w-max 
      2xl:bg-gray-50
      bg-no-repeat
      " 
      style={{backgroundImage:`url(${images.bghero2})`}}>
    <div ref={ref} className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 2xl:rounded-xl">
        <div
        className="
        py-10 static sm:py-16 lg:py-24">
            <motion.div animate={hero2} initial={{ opacity: 0 }} className="grid  items-center grid-cols-1 lg:grid-cols-2 lg:gap-x-8 2xl:gap-x-20">
                <div className="
                  z-10
                  relative
                  sm:relative 
                  lg:relative 
                  xl:relative
                  2xl:relative
                  xl:left-[-10%]
                  xl:bottom-[-196px]
                  lg:bottom-[-200px]
                  2xl:bottom-[-246px]
                  2xl:left-[-5%]
                  lg:order-2
                  ">
                  <div className='
                    bg-transparent
                    lg:bg-white 
                    xl:bg-white
                    xl:visible
                    lg:px-8 
                    lg:py-8
                    2xl:px-12
                    2xl:py-5
                    '>
                    <p className='
                      font-bold 
                      text-xl 
                      xl:text-left 
                      text-white 
                      lg:text-black 
                      xl:text-2xl 
                      xl:text-black 
                      2xl:text-left
                      2xl:text-2xl
                      leading-9'> TENTANG KAMI </p>
                  </div>
                  <div className='
                  xl:bg-black 
                  lg:bg-black 
                  lg:px-8
                  lg:py-5
                  lg:text-justify
                  xl:px-8 
                  xl:py-5 
                  xl:text-justify 
                  xl:h-[100px]
                  2xl:px-12
                  2xl:h-[150px]
                  2xl:text-justify'>
                  
                    <p className='
                      text-left 
                      text-white 
                      xl:text-white
                      lg:text-white
                      py-2 
                      tracking-tighter'>
                      Bangun Rahmat Teknik telah
                      berdiri sejak tahun 2000 dan
                      telah melayani berbagai 
                      perusahaan dengan bidangnya
                      masing-masing.
                    </p>
                  </div>
                    <img className="w-64 shadow-xl bg-black rounded-xl" src="" alt="" />
                </div>    
            </motion.div>
        </div>
    </div>
</section>
  )
}

export default Hero2
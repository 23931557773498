import React, {useState, useEffect} from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useAnimationControls ,motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { images } from '../../constants';
import { useNavigate } from 'react-router-dom';

import './OurProduct.css'
import { urlFor, client } from "../../client";



const OurProduct = () => {
    const ourProduct = useAnimationControls();
    const [ref, inView] = useInView();
    const navigate = useNavigate();
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await client.fetch(
                    `*[_type == "product"] | order(_updatedAt desc)`
                );
                console.log(response);
                setProductData(response);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();

        if (inView) {
            ourProduct.start({
                opacity: [0, 1],
                transition: {
                    ease: 'easeInOut', duration: 1.7
                }
            });
            // console.log('in view')
        }
        if (!inView) {
            ourProduct.start({
                opacity: 0,
                transition: {
                    duration: 1
                }
            });
            // console.log('not in view');
        }
    }, [ourProduct, inView]);
//Owl Carousel Settings
const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 3
        }
    }
  };

  return (
    <section
      ref={ref}
      id="testimonial"
      className="testimonials bg-white pt-70 pb-70"
    >
      <motion.div
        animate={ourProduct}
        initial={{ opacity: 0 }}
        className="container mx-auto  px-5  items-center"
      >
        <h4 className="text-4xl font-semibold text-center">Produk Kami</h4>

        <p className="text-center text-xl mt-3 lg:mx-24">
          Kami menawarkan produk-produk inovatif dan berkualitas tinggi yang
          akan membantu pelanggan kami mencapai tujuan dengan lebih efektif dan
          memastikan kepuasan pembelian.
        </p>
        <div className="row">
          <div className="col-md-12">
            {" "}
            {productData.length && (
              <OwlCarousel
                id="customer-testimonoals"
                className="owl-carousel owl-theme"
                {...options}
              >
                {productData.length === 0 ? (
                  <div className="item">
                    <div className="shadow-effect w-[190px]">
                      <img className="img-circle z-20" src={images.dummy} />

                      <p className="pr-24 text-left text-xl font-semibold">
                        Produk
                      </p>
                      <p className="pr-24 text-left text-xs font-medium leading-5">
                        Lorem ipsum dolor amet
                      </p>
                    </div>
                    <div className="testimonial-name py-4">
                      <h5 className="text-lg font-medium">Lihat Produk</h5>
                    </div>
                  </div>
                ) : (
                  productData.slice(0, 4).map((product) => (
                    <div className="item" key={product._id}>
                      <div className="shadow-effect w-[320px] md:w-[390px] relative justify-center">
                        <p className="lg:pr-24 pl-6 pr-20 lg:pl-0 text-left text-md lg:text-lg font-semibold line-clamp-2">
                          {product.namaproduk}
                        </p>
                        <p className="lg:pr-24 pl-6 pr-20  lg:pl-0 text-left text-[10px] font-medium leading-5 line-clamp-5 md:line-clamp-5">
                          {product.descsingkat}
                        </p>
                        {product.imgproduct
                          .slice(0, 1)
                          .map((image, index) => (
                            <img
                              key={index}
                              src={urlFor(image).url()}
                              alt={product.namaproduk}
                              placeholder={urlFor(image).url()}
                              effect="blur"
                              
                              className="img-circle lg:h-[105%] absolute h-[80%] left-[50%] md:left-[60%] object-scale-down z-20 "
                            />
                          ))}
                      </div>
                      <button
                        onClick={() =>
                          navigate(`/product-detail/${product._id}`)
                        }
                        className="testimonial-name left-[-10%] sm:left-[-25%] lg:left-[-20%] py-4"
                      >
                        <h5 className="text-lg font-medium">Lihat Produk</h5>
                      </button>
                    </div>
                  ))
                )}
              </OwlCarousel>
            )}
          </div>
        </div>
      </motion.div>
    </section>
  );
}

export default OurProduct
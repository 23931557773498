import Scrollbar from 'smooth-scrollbar';
import React, {useState, useEffect} from "react";
const Scroll = () => {

    const options = {
        damping : 0.07,
      }
    
        useEffect(() => {
    
        Scrollbar.init(document.body, options);   
    
        return () => {
           if (Scrollbar) Scrollbar.destroy(document.body)
        }  },[])
    
}

export default Scroll;

